const {
  REACT_APP_AUTH0_TENET_DOMAIN: AUTH0_TENET_DOMAIN = '',
  REACT_APP_AUTH0_API_IDENTIFIER: AUTH0_API_IDENTIFIER = '',
  REACT_APP_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID = '',
  REACT_APP_AUTH_URL: AUTH_URL = '',
  REACT_APP_MAIN_PAGE_URL: MAIN_PAGE_URL = 'https://open-finance.ai',
  REACT_APP_TIME_PRE_LOGOUT: TIME_PRE_LOGOUT = 10000,
} = process.env

export {
  AUTH0_TENET_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH_URL,
  AUTH0_API_IDENTIFIER,
  MAIN_PAGE_URL,
  TIME_PRE_LOGOUT
}